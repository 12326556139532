<template>
    <div class="ProjectDisplay">
        <div class="top-box">
            <div class="content-box">
                <h1>矢量专题图服务和设备版本管理服务等功能开发实施项目</h1>
                <p>
                        全面透彻的感知。通过传感技术，实现对城市管理各方面监测和全面感知。该项目通过对矢量专题图服务和设备版本管理服务等12个一级功能模块32个二级功能的开发与部署实施工作，极大的缩短了响应时间，从信息安全性角度考虑，在进行项目建设时，符合了客户信息安全规范，需要充分考虑了数据的安全性，杜绝各种数据安全隐患。严格权限访问控制，用户在经过身份认证后，只能访问其权限范围内的数据，只能进行其权限范围内的操作。不同的用户具有不同的身份和权限，需要在用户身份真实可信的前提下，提供可信的授权管理服务，保护了数据不被非法越权访问和篡改，确保了数据的机密性和完整性。能经受住来自互联网的一般性恶意攻击。
                    智慧城市利用各类随时随地的感知设备和智能化系统，智能识别、立体感知城市环境，状态，
                    位置等信息的全方位变化，对感知数据进行融合，分析和外理，并能与业务流程智能能化集成，
                    继而主动做出响应，促进城市各个关键系统和谐高效的运行
                </p>
            </div>
        </div>
        <div class="top-box">
            <div class="content-box">
                <h1>省管产业单位新一代信息系统供应商数据治理实施服务项目</h1>
                <p>
                    为满足项目运维支撑要求，在保障系统供应商信息准确、数据唯一的基础上，优化省管产业单位新一代系统供应商基础数据。主要包括重复供应商数据的清理、供应商档案相关资料的补充、纠正错误供应商档案数据等工作。实现了省管产业单位基础数据的精准应用，满足了项目单位对省管产业单位管控需要，满足了基层单位自身集团化管理、精益化管理、行业化管理等方面的需求，服务省管产业单位管理提升，支撑省管产业单位改革改制，实现数据的标准化转变。
                </p>
            </div>
        </div>
        <div class="top-box">
            <div class="content-box">
                <h1>OpenText系统开发实施项目</h1>
                <p>
                    该项目支持财务共享建设，通过搭建会计档案管理系统，将各地区、各公司、各项目产生的原始凭证扫描形成电子化文件，解决实物文件传递的安全性、准时性以及文件审阅的方便性等问题，同时需要实现，影像文件一处上传多处调用，提高效率的目的。建设OpenText xECM档案管理系统完成历史档案库迁移，迁移过程平稳，数据完整，使企业在较低的成本和较小的风险下，扩充业务处理能力，提高运营效率。
                </p>
            </div>
        </div>
    </div>
</template>
<script>
export default {
    data(){
        return {

        }
    }
}
</script>
<style lang="less" scoped>
.ProjectDisplay{
    width: 100%;
    height: 100%;
}
.top-box{
    width:90%;
    margin: 0 auto;
    .content-box{
        width:100%;
        p{
            text-indent: 20px;
            font-size: 1.3rem;
            color: #878787;
        }
    }
    .right-img{
        flex: 1;
        margin:0 0 0 150px;
    }
    .right-img>img{
        width:688px;
        height:450px;
        background-size: 100% 100%;
    }
    
}
.bt-box{
    width:100%;
    height:80px;
    margin: 0 auto;
    background-color: #f5f5f5;
}
.bt-box>h1{
    margin: 0;
    line-height: 80px;
    padding: 0 0 0 100px;
}
.text-box{
    width:95%;
    margin: 0 auto;
}
.items{
    display: flex;
}
.items>p{
    margin: 0;
    font-size:18px;
    color: #878787;
}
.items>.p1{
    padding:10px 0 0 0;
}


</style>